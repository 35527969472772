@use '@angular/material' as mat;

@import 'variables.scss';
@import "src/mixins.scss";



// Plus imports for other components in your app.
/*---------------------------------*/
/* Theme */
/*---------------------------------*/

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,900|Livvic:400,600&display=swap');


$custom-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($theme-dark, 400),
    accent: mat.define-palette($theme-primary,  400),
  ),
  typography: mat.define-typography-config(
    $font-family: 'Open Sans, sans-serif',
    $body-1: mat.define-typography-level(16px, 24px, 500),
    $button: mat.define-typography-level(18px, 48px, 600),
  )
));

@include mat.core-theme($custom-theme);
@include mat.all-component-themes($custom-theme);

$title-typography: mat.define-typography-config(
  $font-family: 'Livvic, sans-serif',
  $button: mat.define-typography-level(18px, 48px, 600),
);
@include mat.button-typography($title-typography);

/*----------------------------------------------------*/
/* siliceum default scss basis
/*----------------------------------------------------*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
    height: 100%;
    height: 100vh;
  }

html, body, app {
  height: 100%;

}

body {
  font-size: 16px!important;
  margin: 0;
  padding: 0;
  font-family: $font-family-content;
  font-size: $font-size-base;
  line-height: $line-height-base;
  background-color: $background-color;
  color: #06042D!important;
  min-height: 100%;
  height: 100%;
}
.mat-step-label-selected, .mat-step-text-label {
  font-size: 16px!important;
}
.mat-step-icon-selected {
  background-color: map-get($colors, primary)!important;
}

h1, h2, h3, h4, h5 {
  font-family: $font-family-title;
  font-weight: 800;
  display: flex; 
  align-items: center
}


h1 {
  font-size: 2em;
}

h2, h3, h4 {
  margin-bottom: 10px!important;
}

h4 {
  margin-top: 5px;
}

html, body { height: 100%; }
body { 
  margin: 0; 
  background-color: #FFF;

}

form {
  padding: 0!important;
}

// Remove the lateral arrows for input[type="number"]
input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}

label {
  font-weight: bold;
}

/*---------------------------------*/
/* Utilities
/*---------------------------------*/
.align-items-row {
  display: flex;
  align-items: center;
}

.align-button-items-row {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;

  mat-spinner {
    margin-left: 10px;
  }
}
mat-icon {
  &[color="success"] {
    color: map-get($map:$colors, $key: success);
  }
  
  &[color="danger"] {
    color: map-get($map:$colors, $key: danger);
  }
  
  &[color="warn"] {
    color: map-get($map:$colors, $key: warn);
  }
}

.full-width {
  width: 100%;
}


.mat-mdc-button-base {
  height: 44px!important;
  font-weight: 400;
}
.half-width {
  min-width: 320px;
  width: calc(50% - 10px);
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.third-width {
  min-width: 200px;
  width: calc(33.33% - 10px);
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.two-thirds-width {
  min-width: 200px;
  width: calc(66.66% - 10px);
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.small-width {

  min-width: 250px;
  max-width: calc(50% - 10px);
  &:not(:last-child) {
    margin-right: 10px;
  }
}


.visibility-icon {
  color: lighten(map-get($map: $colors, $key: dark), 20%);
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.disabled {
  display: none;
}

// ------------------------------------------
// Mat card
mat-card {
  box-shadow: none !important;
}
mat-card-title {
  padding-bottom: 15px;
}
mat-card-actions {
  &>*:not(:last-child) {
    margin-right: 8px;
  }
}
// ------------------------------------------


.link {
  cursor: pointer;
}

a.link { 
    color: map-get($map: $colors, $key: primary);
    text-decoration: none;
    font-weight: bold;
    animation: all ease-in-out 100ms;

    &:hover {
      animation: all ease-in-out 100ms;

      color: map-get($colors, dark-primary);
    }
}



.form-actions {
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-flow: row;
  justify-content: space-between;
  a {
    display: block;
  }

  
  [slot="begin"] {
    margin-right: auto;
  }
  [slot="end"] {
    margin-left: auto;
  }
}


/*---------------------------------*/
/* Panel */
/*---------------------------------*/
@mixin panel-color($color-label, $color-accent, $color-contrast: map-get($map:$colors, $key: light)) {
  &[color="#{$color-label}"] {
    border: 3px solid $color-accent;
    .panel-icon {
      background-color: $color-accent;
      color: $color-contrast;
    }
  }
  .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: #FFF!important;
  }
  mat-spinner {
    width: 25px;
  }
}

.panel {
  display: flex;
  flex-flow: row;
  border-radius: 4px;

  background-color: map-get($map:$colors, $key: light);
  box-shadow: 0px 0px 15px rgba(0,0,0,0.08);

  width: 100%;
  min-width: 100%;

  &.half-width {
    width: calc(50% - 30px);
    min-width: 300px;
    margin-right: 15px;
  }
  &.third-width {
    width: calc(33% - 30px);
    min-width: 300px;
    margin-right: 15px;
  }

  margin: 8px 0;
 
  .panel-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    mat-icon {
      display: block;
    }
  }
  .panel-content {
    padding: 20px;
    
    h1, h2, h3 {
      margin-top: 0!important;
      padding-top: 0!important;
    }
  
  }
  @include panel-color("success",  map-get($map:$colors, $key: success));
  @include panel-color("warn",  map-get($map:$colors, $key: warn));
  @include panel-color("danger",  map-get($map:$colors, $key: danger));
  @include panel-color("primary",  map-get($map:$colors, $key: primary));
  @include panel-color("dark",  map-get($map:$colors, $key: dark));
}

/*---------------------------------*/
/* Page utilities
/*---------------------------------*/

.page {
  box-sizing: border-box;
  display: block;
  width: 100%;
  margin-left: 0px;
  min-height: calc(100% - 44px);
}

.page-header {
  background-color: map-get($map: $colors, $key: light);
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 25px 20px;
  h1, h2, h3 {
    margin: 0;
  }
}

.page-title {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  box-sizing: border-box;
  //border-left: 12px solid map-get($map: $colors, $key: dark);  
  //box-shadow: 0px 4px 15px rgba(0,0,0,0.08);
  //background-color: darken(map-get($map: $colors, $key: light), 0);
  border-radius: 5px;

  min-width: 320px;

  @media (min-width: $small-screen-step-2) {
    margin: 0 40px;
    width: calc(100% - 80px);
  }

  @media (max-width: $small-screen-step-2) {
    max-width: 800px;
    width: calc(100% - 20px);
    min-width: 320px;
    margin: 0 20px;
  }

  h1 {
    margin: 0!important;
    padding: 0;
  }

  .mat-icon {
    display: block;
    width: 24px;
    overflow: visible !important;
    margin-right: 8px;
  }

  .subtitle {
    &.icon-padding {
      padding-left: 36px;
    }
  }


}

.page-content {
  box-sizing: border-box;
  width: calc(100% - 40px);
  margin-top: 0;
  margin: 0 20px;
  padding: 12px 0px;
}


.adaptative-half-page-content {
  box-sizing: border-box;
  min-width: 600px;
  max-width: 800px;
  margin: 0 20px;
  display: block;
  height: fit-content;
  padding: 12px 0px;

  
  @media (max-width: $small-screen-step-2) {
    width: calc(50% - 20px);
    margin: 0 10px;
  }
  

  @media (min-width: $small-screen-step-2) {
    width: calc(50% - 90px);
    &:first-of-type {
      margin-right:10px;
    }
    &:not(:first-of-type) {
      margin-left: 40px;
    }
  }

  

  border-radius: 8px;
}

.row-page-content {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.actions {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
  padding: 8px 0;

  *:last-child {
    margin-right: 0!important;
  }

  a, button:not(:last-child){
    margin-right: 20px!important;
  }

  &.align-right {
    justify-content: flex-end;
  }
}

.adaptive-page_600 {
  display: block;
  width: 100%;
  max-width: 600px!important;
}

/*---------------------------------*/
/* List utilities
/*---------------------------------*/

.list {
  width: 100%;
  padding: 8px 0;
  &.cdk-drop-list-dragging .list-item:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  &.mosaic {
    flex-wrap: wrap;
  }
}

.list-item {
  display: block;
  background-color: map-get($map: $colors, $key: light);
  border-left: solid 8px map-get($map: $colors, $key: dark);

  box-sizing: border-box!important;
  
  border-radius: 4px;
  padding: 8px 15px;
  margin: 8px 0;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0,0,0,0.15);



  &.danger{
    border-left-color: map-get($map: $colors, $key: danger) !important;
  }
  &.success{
    border-left-color: map-get($map: $colors, $key: success) !important;
  }
  &.warn{
    border-left-color: map-get($map: $colors, $key: warn) !important;
  }

  &.condensed {
    border-radius: 0px;
    padding: 4px 8px;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.08);
    border-left: solid 4px map-get($map: $colors, $key: dark);
  }

  &.cdk-drag-preview {
    box-shadow: 0px 13px 15px rgba(0,0,0,0.15);
  }
  &.cdk-drag-placeholder {
    opacity: 0;
  }
  &.cdk-drag-animating {
    transition: all 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  
  .list-item-handle {
    padding-right: 10px;
    color: map-get($map: $colors, $key: dark-grey);
    vertical-align: middle;
    cursor: grab;
  }

  .list-item-inner_left {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    line-height: 1em;
    &>div {
      margin-right: 10px;
    }
  }


  .list-item-inner_right {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }
}


/*---------------------------------*/
/*Checkbox  List utilities
/*---------------------------------*/

.checkbox-list {
  display: flex;
  flex-flow: column;
  padding-right: 40px;
  mat-checkbox {
      padding: 5px 0;
  }
}
.checkbox-mosaic {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}


.slide-toggle-list {
  display: flex;
  flex-flow: column;
  padding-right: 40px;
  margin-bottom: 20px;
  mat-slide-toggle {
      padding: 20px 0;
  }
}

.toggles{
  margin: 1.2rem 0;
  mat-slide-toggle{
      margin: 1.3rem;
  } 
}

/* Used to aligned the toggle and the element on the same row */
.toggle-row {
  mat-slide-toggle{
      margin: 0;
      padding: 0 5px;
  }
  display: inline-flex;
  align-items: center;
}
/*---------------------------------*/
/* Inputs-List utilities
/*---------------------------------*/

.inputs-list>.input-item {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  background-color: map-get($map: $colors, $key: light);
  padding: 10px 20px;
  margin-bottom: 8px;
  .input-item_index {
      background-color: map-get($map: $colors, $key: dark);
      color: map-get($map: $colors, $key: light);
      border-radius: 40px;
      font-weight: bold;
      box-sizing: border-box;
      line-height: 40px;
      min-width: 40px;
      min-height: 40px;
      text-align: center;
      vertical-align: center;
      margin-right: 20px;
  }
  mat-form-field {
      width: 100%;
  }
}
.inputs-list_actions {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  margin-top: 20px;
}



/*---------------------------------*/
/* mat-button utilities
/*---------------------------------*/

mat-button[color="dark"] {
  background-color: map-get($map: $colors, $key: dark);
}

/*---------------------------------*/
/* unit field utilities
/*---------------------------------*/
.unit-field {
  width: 3em;
  margin-right: 15px;
}

/*---------------------------------*/
/* radio buttons utilities
/*---------------------------------*/

.radio-button-list {
  &mat-radio-group {
      padding-left: 15px;
      padding-bottom: 15px;
      display: flex;
      flex-flow: column;
  }

  mat-radio-button {
    display: block;
    padding-right: 15px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px;
  padding-top: 0;
}
// responsive class for items with delete button

@media screen and (min-width: 465px) {
  .row-with-delete-button{
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 15px;
    mat-form-field{
       flex: 1 0 0%;
    }
    button{
        position: absolute;
        top: 0;
        right: 0;
    }
  }
}

  .row-with-delete-button{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  button{
    margin-left: auto;
  }

}

/*-----------------------------------*/
/* div color utilities
/*-----------------------------------*/

@mixin div-color($color-label, $color-accent, $color-contrast: map-get($map:$colors, $key: dark)) {
  div[color="#{$color-label}"] {
    background-color: $color-accent;
    color: $color-contrast;
  }
}

@include div-color('grey', map-get($map:$colors, $key: grey));


/*-----------------------------------*/
/* Button utilities
/*-----------------------------------*/

button > mat-spinner {
  max-width: 30px;
  padding-left: 10px;
}

/*-----------------------------------*/
/* Expansion with form utilities
/*-----------------------------------*/

.list-item_expansion-with-form {
    mat-expansion-panel-header {
        height: fit-content;
        margin: 0;
        padding: 0;
        mat-panel-title {
            padding: 5px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .list-item_title {
                margin: 0;
                padding: 5px;
                margin-left: 10px;
                text-align: left;
            }
            .list-item_marker-fab, .list-item_marker-button {
                display: block;
                margin: 5px;
            }
        }
    }

    .selected {
        border-left: solid 8px map-get($map: $colors, $key: primary);
        box-shadow: 0px 0px 0px rgba(0,0,0,0.01);
    }
}

::ng-deep .mat-expansion-panel-body {
    background-color: map-get($map: $colors, $key: shader-light);
}

/*-----------------------------------*/
/* Virtual lists
/*-----------------------------------*/

.virtual-list-viewport {
    width: 100%;
    height: calc(100vh - 350px);
    min-height: 500px;
}

.virtual-list-item {
    min-height: 58px;
    width: 100%;
    padding-bottom: 8px;
}

/*-----------------------------------*/
/* Infos section
/*-----------------------------------*/

.infos {
  margin-top: 20px
}

/*-----------------------------------*/
/* Fix suffix & Prefix
/*-----------------------------------*/

*[matSuffix] {
  padding-right: 8px;
}

*[matPrefix] {
  padding-left: 8px;
}

/*-----------------------------------*/
/* Results line styles
/*-----------------------------------*/
.result_line_ko {
  background-color: rgba(map-get($map: $colors, $key: danger), 0.03);
}

/*-----------------------------------*/
/* Mat chip update with colors
/*-----------------------------------*/
mat-chip {
  display: flex;
  justify-content: center;
  align-items: center;
  mat-icon {
    display: inline-block;
  }

  &[color="success"] {
    background-color: map-get($map: $colors, $key: success);
    --mdc-chip-label-text-color: #fff;
    --mdc-chip-with-trailing-icon-trailing-icon-color: #fff;
  }
}

/*----------------------------------*/
// Sections
/*-----------------------------------*/


.section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 15px 25px;
  background-color: map-get($map: $colors, $key: light);
  border-radius: 8px;

  margin-bottom: 15px;

  box-shadow: 0px 0px 15px rgba(0,0,0,0.05);
  position: relative;

  h1, h2, h3 {
    &:first-of-type {
      margin-top: 0!important;
      padding-top: 0!important;
    }
    margin-bottom: 5px;
  }

  ul {
    margin: 0 20px;
  }
  p {
    margin: 8px 0px;
  }

  .section-actions {
    position: absolute;
    top: 15px;
    right: 25px;
  }
}

/*----------------------------------*/
// Summary
/*-----------------------------------*/

.summary {
  display: flex;
  flex-flow: row;
  width: 100%;
  padding-top: 20px;
  justify-content: center;
  align-items: flex-start;

  .summary-link {
      height: 80px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      mat-icon {
          margin: auto;
          transform: scale(2);
      }
  }
  .summary-item {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      width: 120px;
      max-width: 120px;
      img {
        @include soft-shadow(map-get($map:$colors, $key: dark));
        box-sizing: content-box;
        max-width: 80px;
        height: 80px;
        margin-bottom: 10px;
        border-radius: 8px;
      }
      font-weight: bold;
      text-align: center;
      font-size: small;
  }
}