$colors: (
    dark-secondary: #D9043D,
    secondary:darken(#F2D338, 5%),
    primary: #6FBF9A,
    dark-primary: darken(#6FBF9A, 5%),
    success: #5EB56A,
    dark-success: darken(#5EB56A, 5%),
    neutral: #60C4E3,
    dark-neutral: darken(#60C4E3, 10%),
    light: #FFF,
    shader-light: #f5f4f4,
    grey: #e9e3e3,
    dark-grey: #262626,
    dark: #06042D,
    lighten-dark: #06042D,
    warn: #ED6D1D,
    dark-warn: darken(#ED6D1D, 10%),
    danger: #E6294A,
    dark-danger: darken(#E6294A, 10%),
);

$theme-dark: (
    50: #06042D,
    100: #06042D,
    200: #06042D,
    300: #06042D,
    400: #06042D,
    500: #06042D,
    600: #06042D,
    700: #06042D,
    800: #06042D,
    900: #06042D,
    contrast: (
        50: map-get($map: $colors, $key: light),
        100: map-get($map: $colors, $key: light),
        200: map-get($map: $colors, $key: light),
        300: map-get($map: $colors, $key: light),
        400: map-get($map: $colors, $key: light),
        500: map-get($map: $colors, $key: light),
        600: map-get($map: $colors, $key: light),
        700: map-get($map: $colors, $key: light),
        800: map-get($map: $colors, $key: light),
        900: map-get($map: $colors, $key: light),
    )
);

$theme-primary: (
    50: map-get($map: $colors, $key: primary),
    100: map-get($map: $colors, $key: primary),
    150: map-get($map: $colors, $key: primary),
    200: map-get($map: $colors, $key: primary),
    300: map-get($map: $colors, $key: primary),
    400: map-get($map: $colors, $key: primary),
    500: map-get($map: $colors, $key: primary),
    600: map-get($map: $colors, $key: primary),
    700: map-get($map: $colors, $key: primary),
    800: map-get($map: $colors, $key: primary),
    900: map-get($map: $colors, $key: dark-primary),
    contrast: (
        50: #06042D,
        100: #06042D,
        200: #06042D,
        300: #06042D,
        400: #06042D,
        500: #06042D,
        600: #06042D,
        700: #06042D,
        800: #06042D,
        900: #06042D,
    )
);

$background-color: map-get($colors, light);
$content-color: map-get($colors, dark);
$title-color: map-get($map: $colors, $key: dark);
$title-color-bold: map-get($map: $colors, $key: primary);

$header-title-color: map-get($map: $colors, $key: light);

$font-family-title: 'Livvic', sans-serif;
$font-family-title-bold: 'Livvic', sans-serif;
$font-family-content: 'Open Sans', sans-serif;

$h1-font-size: 3rem;
$h2-font-size: 1.65rem;
$h2-font-size-bold: 1.1rem;
$h3-font-size: 1.35rem;

$illustration-size: 150px;

$font-size-base: 1em;
$font-size-detail: 0.9em;
$font-size-option-item: 1.1rem;
$line-height-base: 1.7;

$footer-background-color: map-get($map: $colors, $key: dark);
$footer-color: map-get($map: $colors, $key: light);
$footer-padding: 20px 20%;
$footer-font-size: 0.9rem;
$footer-line-height-base: 200%;

$link-color: map-get($map: $colors, $key: secondary );
$link-hover-color: map-get($map: $colors, $key: dark-primary );

$blockquote-color: map-get($map: $colors, $key: dark);
$blockquote-border-color: map-get($map: $colors, $key: tertiary);

$small-screen-step-1 : 600px;
$small-screen-step-2 : 1400px;
$small-screen-step-3 : 1600px;
$small-screen-step-4 : 1800px;